export default function Imprint() {<br />
  return <div className="m-0 container sm:mx-auto"><br />
    <h1 className="text-xl text-center p-3 font-mono">Imprint</h1>
  <p className="font-mono break-word text-center p-3">
  Trend Sale IT FZCO
  <br />Dubai Digital Park
  <br />Dubai Silicon Oasis
  <br />Dubai, United Arab Emirates
  <br />
<br /> <br />
phone/Tel.: +971 5 853 464 80
<br />eMail: info@foryou.to
<br />Internet: https://www.foryou.to
    </p>
</div>
}
