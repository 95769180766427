import dog from '../assets/dog.jpg'

export default function Gamecard() {
    return (
        <div className="flex justify-center p-3">
        <div className="rounded-lg shadow-lg bg-white max-w-sm">
          <a href="#!">
            <img className="rounded-t-lg" src={ dog } alt=""/>
          </a>
          <div className="p-6">
            <h5 className="text-gray-900 text-xl font-medium mb-2">What is your dog breed?</h5>
            <p className="text-gray-700 text-base mb-4">
              Press on the Dog to see which Breed you are.
            </p>
            <button type="button" onClick={()=>{ alert('Disabled in development mode.'); }} className="text-white inline-block px-6 py-2.5 bg-maincolor  font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">Button</button>
          </div>
        </div>
      </div>
        )
    }
