import React, { Component } from "react"
import Gameecard from "./components/Gamecard"
import Navbar from "./components/navbar"
import Footer from "./components/footer"
import Privacy from "./pages/Privacy"
import Home from "./pages/Home"
import Terms from "./pages/Terms"
import Imprint from "./pages/Imprint"
import { Route, Routes } from "react-router-dom"

class App extends Component {

  componentDidMount(){
    const script = document.createElement('script');
    script.src = 'https://www.facebook.com/assets.php/en_US/fbinstant.latest.js';
    script.id = 'fbinstant';
    document.body.appendChild(script);
    script.onload = () => {
      window.FBInstant.initializeAsync().then(function() {
        window.FBInstant.startGameAsync().then();
    });
  }
}



  render() {
    return (
    <>
      <Navbar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/imprint" element={<Imprint />} />
        </Routes>
      <Footer />
    </>
    );
  }
}

export default App
