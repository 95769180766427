import React from "react";
import {
  Box,
  Container,
  Row,
  Column,
  FooterLink,
  Heading,
} from "./FooterStyles";

const Footer = () => {
  return (
    <Box>
      <Container>
        <Row>
          <Column>
            <Heading>Legal</Heading>
            <FooterLink href="/Terms">Terms</FooterLink>
            <FooterLink href="/Privacy">Privacy</FooterLink>
            <FooterLink href="/Imprint">Imprint</FooterLink>
          </Column>

          <Column>
            <Heading>Social Media</Heading>
            <FooterLink href="https://www.facebook.com/badzcutepetsapp/">
              <i className="fab fa-facebook-f">
                <span style={{ marginLeft: "10px" }}>
                  Facebook
                </span>
              </i>
            </FooterLink>

          </Column>
        </Row>
      </Container>
      <h1 style={{ color: "grey",
                   textAlign: "center",
                   marginTop: "50px" }}>
        The casual gaming platform ForYou.to
      </h1>
    </Box>
  );
};
export default Footer;
